import React, { FC } from 'react';
import styled from '@emotion/styled';

import { Container, Box } from 'components';
import DividerIcon from 'images/divider.svg';
import { ThemeUIStyleObject } from 'theme-ui';

const Divider = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -58px;
  margin-top: -11px;
`;

interface SectionProps {
  noDivider?: boolean;
  outterSX?: ThemeUIStyleObject;
  innerSX?: ThemeUIStyleObject;
}

const Section: FC<SectionProps> = ({ children, outterSX, innerSX, noDivider, ...props }) => {
  return (
    <Box
      as="section"
      sx={{
        ...outterSX,
      }}
    >
      <Container {...props}>
        <Box sx={{ pt: [10, 10, 10, 14], pb: [10, 10, 10, 14], ...innerSX }}>
          {children}
        </Box>
      </Container>
      {!noDivider && 
        <Divider>
          <DividerIcon />
        </Divider>
      }
    </Box>
  );
};

export default Section;
